.product-card {
    border: 10px solid;
    border-radius: 50%;
    background-color: #ffffff;
    width: 280px;
    height: 280px;
    transition: transform 0.2s ease-in;

    &:hover {
        transform: scale(1.25);

        .product-title {
            transform: scale(1);
        }
    }

    img, svg {
        width: 260px;
        height: 260px;
        border-radius: 50%;
        transition: transform 0.2s ease-in;
    }

    .product-title {
        position: absolute;
        display: inline-block;
        left: 200px;
        top: -20px;
        padding: 10px 20px 5px;
        border-radius: 25px 25px 25px 0px;
        h3 {
            color: #ffffff;
        }
    }

    &.-blue {
        border-color: $knot-blue;
        .product-title {
            background-color: $knot-blue;
        }
    }

    &.-yellow {
        border-color: $knot-yellow;
        .product-title {
            background-color: $knot-yellow;
        }
    }
}

#station-product-title {
    margin-top: 10%;
}

#station-product-list {
    margin-top: 19%;
}

// position for mobile
.product-card {
    margin: 20px auto;
}

.products-nav.nav.nav-pills {
    .nav-item.nav-link {
        font-size: 1.5rem;
        font-family: "futura-pt-bold";
        background-color: $base-bg-color;
        color: #ffffff;
        margin: 0.5rem;
        padding: 0.5rem;
        border-radius: 3rem;
        &.active {
            background-color: $knot-yellow;
        }
    }

    &.products-nav-blue .nav-item.nav-link.active {
        background-color: $knot-blue;
    }
}

#app-stores-link {
    text-align: right;
    & a:first-child {
        position: relative;
        &::before {
            content: ' ';
            position: absolute;
            background-image: url('/images/graphics/arrow_down.svg');
            height: 98px;
            width: 118px;
            right: 160px;
            transform: rotate(0.75turn) scale(0.5);
        }
    }

    img {
        margin: 0 0.5rem 1rem 0.5rem;
        width: 180px;
    }
}

.phone-container {
    position: relative;
    max-width: 200px;
    margin: 2rem auto;

    .phone-border {
        width: 100%;
    }

    .phone-content {
        position: absolute;
        left: 5.2%;
        top: 11%;
        width: 90%;
    }
}

.laptop-container {
    position: relative;
    max-width: 300px;
    margin: 2rem auto;

    .laptop-border {
        width: 100%;
    }

    .laptop-content {
        position: absolute;
        left: 12.2%;
        top: 9.5%;
        width: 76.5%;
        transform: scaleY(1.1);
    }
}

dl.product-spec {
    display: grid;
    grid-template-columns: max-content auto;

    dt {
        grid-column-start: 1;
        padding-right: 1rem;
    }

    dd {
        grid-column-start: 2;
    }

    dt, dd {
        border-bottom: 2px solid $knot-blue;
        margin-bottom: 0.5rem;
    }

    &.-green dt, &.-green dd {
        border-bottom: 2px solid $knot-green;
    }

    &.-yellow dt, &.-yellow dd {
        border-bottom: 2px solid $knot-yellow;
    }
}


// Medium smartphone and more.
@media (min-width: 576px) {
    #app-stores-link a:first-child::before {
        right: 180px;
        transform: rotate(0.75turn) scale(0.7);
    }

    .products-nav.nav.nav-pills.-grouped {
        .nav-item.nav-link {
            margin: 0.5rem 0;
            padding: 0.5rem 0;
            border-radius: 0;

            &:first-child {
                border-radius: 3rem 0 0 3rem;
            }

            &:last-child {
                border-radius: 0 3rem 3rem 0;
            }
        }
    }
}

// Desktop
@media (min-width: 992px) {
    // Show title on mouse over on desktop
    .product-card .product-title {
        transform: scale(0);
        transition: transform 0.2s ease-in;
        left: 220px;
        top: 0;
    }

    dl.product-spec dt {
        padding-right: 2rem;
    }

    #station-product-list {
        margin-top: 13%;
    }
}
