
.story.content {
    img {
        max-width: 100%;
        display: block;
        margin: 0 auto;
    }

    p {
        line-height: 1.8;
    }

    h2 {
        line-height: 1.5;
        font-size: 2.5rem;
    }
}

.card {
    width: 100%;
}
