#index-container {
    background-image: url('/images/graphics/yellow-line.svg');
    background-size: cover;
}

#station-product-main {
    background-image: url('/images/pictures/station-es2-tram.jpg');
    background-size: cover;
    margin-top: -130px;
    padding-top: 130px;
}

.title-bg-image {
    background-size: cover;
    margin-top: -130px;
    padding-top: 130px;
    padding-bottom: 15px;
    background-position: 60% 0;
}

.title-bg-spacer {
    min-height: 25vh;
    h1 {
        white-space: pre-line;
    }
}

/**
 * Tablet and bigger
*/
@media (min-width: 768px) {
    .title-bg-spacer {
        min-height: 30vh;

        h1 {
            position: absolute;
            bottom: 2rem;
            left: 4rem;
        }
    }
}

/**
 * Desktop only (lg+)
 */
@media (min-width: 992px) {
    .title-bg-spacer {
        min-height: 30vh;
        margin-left: 6rem;
    }

    .bg-sub-title {
        margin-left: 6rem;
    }
}

@media (min-width: 1200px) {
    .title-bg-spacer {
        min-height: 40vh;
        margin-left: 8rem;
    }

    .bg-sub-title {
        margin-left: 8rem;
    }
}
