.graphic-right {
    float: right;
    width: 80px;
    height: 100%;

    &.graphic-right-border {
        margin-right: -15px;
    }
}

.graphic-left {
    float: left;
    width: 80px;
    height: 100%;

    &.-border {
        margin-left: -15px;
    }

    &.-lg {
        width: 120px;
    }
}

#graphic-cat {
    margin-top: 20px;
    height: 80px;
    width: auto;
}

.meow-text {
    display: none;
}

#graphic-cat:hover + .meow-text {
    display: block;
    font-size: 25px;
    font-weight: 600;
}

.btn-arrow {
    height: 3rem;

    &.btn-arrow-right {
        -webkit-transform: scaleX(-1);
        transform: scaleX(-1);
    }
}

#cat-paw-left {
    transform: rotate(165deg) scaleY(-1);
    transition: transform .5s ease-in-out;
    width: 80px;
    height: auto;
    margin-left: -30px;
    margin-top: -280px;

    &.move {
        transform: rotate(165deg) scaleY(-1) translateX(50px); 
    }
}

/**
 * Tablet and bigger
*/
@media (min-width: 768px) {
    #cat-paw-left {
        width: 140px;
    }

    #graphic-cat {
        margin-top: 0;
        margin-right: 30px;
        height: 140px;
    }

    #graphic-app {
        margin-top: -100px;
    }

    .graphic-left {
        &.graphic-left-2 {
            margin-top: 350px;
            width: 100px;
        }
    }
}

/**
 * Desktop only (lg+)
 */
 @media (min-width: 992px) {
    .graphic-right {
        margin-top: 100px;
        width: 150px;

        &.graphic-right-2 {
            margin-top: -50px;
        }
    }

    .graphic-left {
        margin-top: 200px;
        width: 120px;

        &.-lg {
            width: 200px;
        }
    }

    #graphic-cat {
        height: 180px;
    }

    #cat-paw-left {
        width: 220px;
    }
}
