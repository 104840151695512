
.btn {
    font-size: 22px;
    font-family: "futura-pt-medium", serif;
    padding: 16px 44px;
    border-radius: 5px;
    font-weight: 600;
    border: 0;
    position: relative;
    z-index: 1;
    transition: 0.2s ease;
}

.btn:focus {
    outline: 0;
    box-shadow: none !important;
}

.btn:active {
    box-shadow: none;
}

.btn-sm {
    font-size: 18px;
    padding: 10px 30px;
}

.btn-xs {
    padding: 5px 15px;
    font-size: 15px;
}

.btn-primary {
    background: $knot-blue;
    color: #fff;
}

.btn-primary.active {
    opacity: 0.8;
}

.btn-primary:active {
    background: $knot-blue !important;
}

.btn-primary:hover {
    background: $knot-blue;
}

.btn-primary.focus,
.btn-primary.active {
    background: $knot-blue !important;
    box-shadow: none !important;
}

.btn-transparent {
    background: transparent;
    color: $knot-blue;
    font-weight: 700;
}

.btn-transparent:active,
.btn-transparent:hover {
    color: $knot-blue;
}

.btn-yellow {
    background-color: $knot-yellow;
    color: #fff;

    &:hover {
        color: #ecdfdf;
    }
}

.btn-rounded {
    border-radius: 50px;
}

.btn-share {
    padding: 0 10px 0 0;

    img {
        border-radius: 5px 0 0 5px;
        height: 32px;
        width: 32px;
        margin-right: 5px;
    }
}

.btn-blue-invert {
    color: $knot-blue;
    background-color: #fff;
    width: 100%;
    padding: 5px 15px;
    margin: 5px 0;

    &:hover {
        color: $knot-blue;
        background-color: #ece6e6;
    }
}