.footer-section {
    background-color: #3d3d3d;
    padding-top: 1rem;
    padding-bottom: 1rem;

    .footer-nav {
        width: 100%;

        a.footer-nav-item {
            color: $knot-yellow;
            font-size: 1.2rem;
            font-weight: 800;

        }

        ul {
            padding-left: 0;
        }

        li.nav-item a {
            color: #ffffff;
        }

        .collapse, .collapsing {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
        }

        & > div {
            margin: 0.5rem 0;
        }
    }
}

@media (min-width: 992px) {
    .footer-section {
        .collapse:not(.show) {
            display: block;
        }
    }
}
