@font-face {
    font-family: "futura-pt-bold";
    font-style: normal;
    font-weight: 700;
    src: local('Futura-PT-Bold'),
        url("../fonts/futura-pt-bold.woff2") format("woff2"),
        url("../fonts/futura-pt-bold.woff") format("woff"),
        url("../fonts/futura-pt-bold.ttf") format("truetype");
    font-display: swap;
}

@font-face {
    font-family: "futura-pt-medium";
    font-style: normal;
    font-weight: 400;
    src: local('Futura-PT'), local('Futura-PT-Regular'),
        url("../fonts/futura-pt-medium.woff2") format("woff2"),
        url("../fonts/futura-pt-medium.woff") format("woff"),
        url("../fonts/futura-pt-medium.ttf") format("truetype");
    font-display: swap;
}
