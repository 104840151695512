p {
    font-weight: 400;
    color: #3f3f3f;
    font-size: 16px;
    line-height: 1.3;
    font-family: "futura-pt-medium", sans-serif;
}

h1 {
    font-size: 4rem;
}

h2 {
    font-size: 3rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #000;
    font-family: "futura-pt-bold", serif;
    line-height: 1.2;
}


.text-blue {
    color: $knot-blue;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: $knot-blue;
    }
}

.text-yellow {
    color: $knot-yellow;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: $knot-yellow;
    }
}

.text-green {
    color: $knot-green;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        color: $knot-green;
    }
}

.title-white {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: #ffffff;
    }
}

.text-primary, a {
    color: $knot-blue;
}

a.grey, a.grey:visited {
    color: #3f3f3f;
    text-decoration: underline;

    &:hover {
        color: #535353;
        text-decoration: underline;
    }
}

a.text-white {
    color: #ffffff;
    text-decoration: underline;

    &:hover {
        color: #d1cece;
        text-decoration: underline;
    }
}

p.knot-round {
    border-radius: 2rem;
    padding: 1rem;
}

/**
 * Desktop only (lg+)
 */
@media (min-width: 992px) {
    h1 {
        font-size: 6rem;
        font-weight: 1000;
        line-height: 1.1;
    }

    h1.smaller {
        font-size: 5rem;
    }

    // bigger h2 on main page
    #index-container {
        h2 {
            font-size: 4rem;
            font-weight: 1000;
            line-height: 1.1;
        }
    }

    p {
        font-size: 20px;
        line-height: 1.2;
    }
}

div.main-text-area {
    margin-left: 1rem;
    margin-right: 1rem;
}

/**
 * Desktop only (lg+)
 */
@media (min-width: 992px) {
    div.main-text-area {
        margin-left: 3rem;
        margin-right: 3rem;
    }
}

/**
 * Extra large desktop (more than xl)
*/
@media (min-width: 1500px) {
    div.main-text-area {
        margin-left: 20%;
        margin-right: 20%;
    }
}

/**
 * Adjustment for very small mobile device
*/
@media (max-width: 575.98px) {
    h2 {
        font-size: 2.5rem;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    h3 {
        font-size: 1.5rem;
    }
}
