#where-background {
    background-image: url('/images/graphics/road.svg');
    background-color: #4d4d4d;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center top;
}

.network-area {
    margin-top: 130px;
}

#network-next {
    margin-bottom: 100px;
}

.btn-network {
    border: 2px solid #ffffff;
    border-radius: 0;
    color: #ffffff;
    padding: 6px 6px;
    font-size: 15px;

    &:hover {
        color: #ffffff;
    }

    &.-red {
        background-color: $knot-red;
    }

    &.-green {
        background-color: $knot-green;
    }

    &.-yellow {
        background-color: $knot-yellow;
    }

    &.-blue {
        background-color: $knot-blue;
    }

    &.-left::after {
        content: ' ';
        display: inline-block;
        background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'><path d='M6 1l8 7-8 7c-1 2-3 0-2-1l6-6-6-6C3 1 5-1 6 1z'/></svg>");
        height: 0.8em;
        width: 0.8em;
        position: relative;
        top: 0.1em;
    }

    &.-right::before {
        content: ' ';
        display: inline-block;
        background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg' aria-hidden='true'><path d='M11 1L3 8l8 7c1 2 4 0 2-1L7 8l6-6c2-1-1-3-2-1z'/></svg>");
        height: 0.8em;
        width: 0.8em;
        position: relative;
        top: 0.1em;
    }
}

.pricing-title {
    width: 100%;
    font-size: 1rem;
    text-align: center;
    border-radius: 50px;
    padding: 5px 15px;
    font-weight: bolder;
    background-color: $knot-blue;
    color: #ffffff;
}

#scooter-rider {
    z-index: 5;
    position: fixed;
    height: 54px;
    width: 30px;
    left: calc(50vw - 15px);
    top: 30%;
    background-image: url('/images/graphics/girl-on-scooter.svg');
    background-size: 100% 100%;
    background-repeat: no-repeat;
    transform: rotateX(180deg);
}

#network-map {
    width: 100%;
    height: 50vh;
    margin-top: -75px;
}

a.faq-question {
    color: #ffffff;
    font-size: 1.2rem;
    font-weight: bold;
 
    &:hover {
        text-decoration: underline;
    }

    &[aria-expanded=true] {
        color: $knot-blue;
    }
}

div.faq-answer {
    margin-top: 5px;
    p {
        margin-left: 10px;
        color: #ffffff;
    }
    ol {
        padding-left: 30px;
    }
    li {
        color: #ffffff;
    }
}

/**
 * Tablet and bigger
*/
@media (min-width: 768px) {
    #where-background {
        background-image: url('/images/graphics/road-large.svg');
    }

    #ride-how {
        counter-reset: step;

        .step {
            position: relative;
        }

        .step::before {
            position: absolute;
            counter-increment: step;
            content: counter(step);
            left: -0.2rem;
            top: -1.4rem;
            color: #ffffff;
            vertical-align: middle;
            font-size: 3rem;
            font-weight: bolder;
            z-index: 5;
        }
    }

    #scooter-rider {
        height: 112px;
        width: 60px;
        left: calc(50vw - 30px);
    }

    .btn-network {
        border: 3px solid #ffffff;
        padding: 8px 22px;
        font-size: 22px;
    }

    .btn-pricing {
        font-size: 1.1rem;
    }

    #accordionQuestion {
        width: 50%;
    }

    .faq-answer {
        position: absolute;
        margin-left: 95%;
        width: 100%;
        top: 0;

        &.collapsing {
            -webkit-transition-property: none;
            transition-property: none;
        }
    }
}

// Desktop
@media (min-width: 992px) {
    .product-card .product-title {
        transform: scale(0);
        transition: transform 0.2s ease-in;
        left: 220px;
        top: 0;
    }

    #scooter-rider {
        height: 132px;
        width: 70px;
        left: calc(50vw - 38px);
    }

    #network-map {
        margin-top: -95px;
        height: 650px;
    }

    .pricing-title {
        font-size: 1.3rem;
    }

    .faq-answer {
        margin-left: 98%;
        width: 200%;
    }
}
