ul.yellow-bullet {
    list-style-type: none;
    margin: 0;
    color: #3f3f3f;
    font-size: 1.2rem;
}

ul.yellow-bullet li::before {
    content: "\2022";
    color: $knot-yellow;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1em;
}

ul.no-bullet {
    list-style-type: none;
    padding-left: 0;
}

li {
    color: #3f3f3f;
    font-family: "futura-pt-medium", sans-serif;
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
    line-height: 1.6;
}

li.social-item {
    display: grid;
    grid-template-columns: max-content auto;

    a {
        grid-column-start: 1;
        vertical-align: middle;
    }

    p {
        grid-column-start: 2;
        padding-left: 5px;
        margin-bottom: 0px;
        display: flex;
        align-items: center;
    }
}


/**
 * Desktop only (lg+)
 */
 @media (min-width: 992px) {
    li {
        font-size: 20px;
        line-height: 1.2;
    }
}
