#order-form {
    background-color: #dddddd;
    border-radius: 25px;
    padding: 20px;

    input, textarea, select {
        border-radius: 20px;
    }

    .select2-container--default .select2-selection--single {
        border-radius: 20px;
        height: calc(1.5em + .75rem + 2px);
        border: 1px solid #ced4da;

        .select2-selection__rendered, .select2-selection__arrow {
            height: calc(1.5em + .75rem + 2px);
            font-size: 1rem;
            font-weight: 400;
            line-height: 2;

            b {
                margin-top: -4px;
            }
        }
    }

    textarea {
        resize: none;
    }

    input[type=checkbox] {
        -moz-appearance:none;
        -webkit-appearance:none;
        -o-appearance:none;
    }

    input[type=checkbox] {
        border-radius: 5px;
        -border-radius: 2px;
        height: 18px;
        width: 18px;
        background: #fff;
        border: 1px solid $knot-yellow;

        & + label {
            margin-left: 0.5rem;
        }

        &:checked {
            background: $knot-yellow;
        }
    }

    select {
        appearance: none;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='none' stroke='currentColor' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'%3e%3cpolyline points='6 9 12 15 18 9'%3e%3c/polyline%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: right 0.5rem center;
        background-size: 1em;
    }

    label {
        font-weight: 500;
        font-size: 1.1rem;
    }

    label.required:after {
        content: "*";
        color: $knot-blue;
        font-size: 1.4rem;
    }

    .form-group.re-align-left {
        text-align: left;
        label {
            margin-left: 0.75rem;
        }
    }

    button[type="submit"] {
        font-size: 1.3rem;
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
    }

    option {
        font-family: "futura-pt-medium", serif;
    }
}

#form-graphic-cat {
    display: none;
}

@media (min-width: 768px) {
    #form-graphic-cat {
        display: block;
        position: absolute;
        bottom: 10px;
        right: 30px;
        transform: scaleX(-1);
    }
}
