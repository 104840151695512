@import "color";
@import "font";
@import "text";
@import "nav";
@import "btn";
@import "graphic";
@import "product";
@import "background";
@import "list";
@import "footer";
@import "order";
@import "stories";
@import "rider";

body {
    line-height: 1.2;
    font-family: "futura-pt-medium", sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 15px;
    background-color: $base-bg-color;
    overflow-x: hidden;
}

.spacer {
    padding-bottom: 80px;
    width: 100%;
}

main {
    // Move main outside the nav
    padding: 0 0 25px 0;
}

.page-title {
    padding: 5px 0 25px;
}

.rounded-img {
    max-width: 80%;
    height: auto;
    border-radius: 50%;
    overflow: hidden;

    &:not(.no-border) {
        border: 10px solid;
        border-color: $knot-blue;

        &.-green {
            border-color: $knot-green;
        }

        &.-yellow {
            border-color: $knot-yellow;
        }
    }

    &.-tiny {
        max-width: 90px;
    }

    &.-small {
        max-width: 150px;
    }

    &.-medium {
        max-width: 280px;
    }
}

#api-partner {
    div:nth-child(4n) {
        .rounded-img {
            border-color: $knot-blue;
        }
    }

    div:nth-child(4n+1) {
        .rounded-img {
            border-color: $knot-yellow;
        }
    }

    div:nth-child(4n+2) {
        .rounded-img {
            border-color: $knot-green;
        }
    }

    div:nth-child(4n+3) {
        .rounded-img {
            border-color: $knot-red;
        }
    }

    div {
        padding: 0.5rem;
    }
}

.carousel-round {
    max-width: 80%;
    margin: 0 auto;
    border: 10px solid;
    border-color: $knot-blue;
    border-radius: 50%;

    .carousel-inner {
        border-radius: 50%;
        overflow: hidden;
        z-index: 1;
    }

    .carousel-control-prev {
        margin-left: -40px;
    }

    .carousel-control-next {
        margin-right: -40px;
    }

    .carousel-control-next, .carousel-control-prev {
        svg.bi {
            font-size: 6rem;
            color: $knot-blue;
        }
    }
}

#dockingCaptions {
    padding-bottom: 9rem;
    padding-top: 15px;

    .carousel-caption {
        padding: 0;
        bottom: unset;
        max-width: 80%;
        margin: auto auto;
        right: 5%;
        left: 5%;
    }
}

@media (min-width: 480px) {
    .carousel-round {
        max-width: 300px;

        .carousel-control-prev {
            margin-left: -55px;
        }
    
        .carousel-control-next {
            margin-right: -55px;
        }
    }

    .rounded-img {
        max-width: 300px;

        &.-small {
            max-width: 180px;
        }

        &.-tiny {
            max-width: 110px;
        }
    }


    #dockingCaptions {
        .carousel-caption {
            max-width: 300px;
        }
    }
}

/**
 * Tablet and bigger
*/
@media (min-width: 768px) {
    .carousel-round {
        max-width: 400px;

        .carousel-control-prev {
            margin-left: -70px;
        }
    
        .carousel-control-next {
            margin-right: -70px;
        }
    }

    .rounded-img {
        max-width: 400px;
    }
}

/**
 * Desktop only (lg+)
 */
 @media (min-width: 992px) {
    .carousel-round {
        max-width: 500px;
    }

    .rounded-img {
        max-width: 500px;
        &.-tiny {
            max-width: 130px;
        }
    }

    #dockingCaptions  .carousel-caption {
        max-width: 500px;
    }
}

::selection {
    background: $knot-blue;
    color: #fff;
}

img {
    vertical-align: middle;
    border: 0;
}

a,
a:hover,
a:focus {
    text-decoration: none;
}

a,
button,
select {
    cursor: pointer;
    transition: 0.2s ease;
}

a:focus,
button:focus,
select:focus {
    outline: 0;
}

a.text-dark:hover {
    color: $knot-blue !important;
}

a:hover {
    color: $knot-blue;
}

.slick-slide {
    outline: 0;
}

.section {
    padding-top: 5px;
    padding-bottom: 5px;
}

.bg-blue {
    background-color: $knot-blue !important;
}

.bg-dark {
    background-color: #222 !important;
}

.social-logo {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin: 0.25rem 0.75rem;
}

.img-roulette-container {
    position: relative;
    img {
        width: 200px;
        height: 200px;
        overflow: hidden;
        object-fit: cover;
        border-radius: 100px;
        border: 10px solid $knot-yellow;
        cursor: pointer;
    }

    &::before {
        content: ' ';
        position: absolute;
        background-image: url('/images/graphics/arrow_down.svg');
        height: 98px;
        width: 118px;
        left: -20px;
        transform: rotate(0.75turn) scale(0.5);
    }
}

.force-square {
    position: relative;
    width: 100%;
    padding-top: 100%;

    img {
        position:  absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

#growing-circle {
    top: 15%;
    left: 0;
    position: absolute;
    z-index: -1;
    width: 35%;
}

#if-award {
    img {
        top: 0;
        right: 0;
        position: absolute;
        z-index: 0;
        height: 30px;
        width: 60px;
    }
}

.z-index-2 {
    z-index: 2;
}

.px-6 {
    padding-left: 5rem !important;
    padding-right: 5rem !important;
}

.flag {
    max-width: 32px;

    &.current {
        margin-top: -5px;
    }
}

.flag-dropdown {
    min-width: 2rem;
    max-width: 5rem;
}

/**
 * sm
*/
@media (min-width: 576px) {
    .img-roulette-container {
        &::before {
            left: -60px;
        }
    }
}

/**
 * Tablet and bigger
*/
@media (min-width: 768px) {
    main {
        // Move main outside the nav
        padding: 10px 0 25px 0;
    }

    .page-title {
        padding: 10px 0 25px;
    }

    #dockingCaptions {
        padding-bottom: 8rem;
    }

    .px-6 {
        padding-left: 4rem !important;
        padding-right: 4rem !important;
    }

    .social-logo {
        width: 40px;
        height: 40px;
    }

    #if-award img {
        height: 60px;
        width: 120px;
    }
}

/**
 * Desktop only (lg+)
 */
@media (min-width: 992px) {
    .spacer {
        padding-bottom: 180px;
    }

    main {
        // Move main outside the nav
        padding: 10px 0 25px 0;
    }

    .img-roulette-container {
        img {
            width: 300px;
            height: 300px;
            border-radius: 150px;
        }

        &::before {
            left: -50px;
        }
    }

    #dockingCaptions {
        padding-bottom: 2rem;
    }

    .px-6 {
        padding-left: 5rem !important;
        padding-right: 5rem !important;
    }

    .social-logo {
        width: 30px;
        height: 30px;
        margin: 0 5px;
    }
}

.quick-link a {
    font-size: 1.1rem;
    margin: 0.25rem;
}

.knot-customer-logos img {
    height: 125px;
    width: auto;
    margin: 0.25rem;
}
