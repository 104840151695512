@import 'color';

nav.navbar {
    .nav-button-and-logo {
        margin-left: -38px;
        border-radius: 0 50px 50px 0;
    }

    ul.navbar-nav {
        background: #cacacaca;
        border-radius: 50px;
        padding-left: 1rem;
    }
}

.navbar-brand img {
    height: 70px;
    padding: 15px 50px 15px 150px;
}

.navbar-dark .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='75' viewBox='0 0 100 75'><line stroke='white' stroke-linecap='round' stroke-width='5' x1='21.45' y1='30.68' x2='76.27' y2='30.68'/><line stroke='white' stroke-linecap='round' stroke-width='5' x1='34.7' y1='46.93' x2='89.52' y2='46.93'/></svg>");
}

.nav-button-and-logo {
    background-color: $knot-blue;
}

.navbar-toggler {
    color: #fff;
    border: none;
}

.navbar {
    z-index: 1;
    .nav-item .nav-link {
        font-family: "futura-pt-bold", serif;
        color: $knot-blue;
        text-transform: uppercase;
        padding: 10px 15px;
    }

    .nav-item.active .nav-link {
        color: $knot-yellow;
    }
}

.navbar-yellow .nav-button-and-logo {
    background-color: $knot-yellow;
}

.navbar-text-white {
    .nav-item .nav-link {
        color: #ffffff;
    }
}

.navbar .dropdown-menu.show {
    background-color: $knot-yellow;
    border-radius: 30px;
    border: none;

    .dropdown-item {
        color: #ffffff;
        border-radius: 50%;
    }

    .dropdown-item:hover {
        background-color: $knot-yellow;
    }
}

.nav-item.-highlight {
    background-color: $knot-blue;
    border-radius: 30px;
    .nav-link {
        color: #ffffff;
    }
}

#ride-dropdown {
    min-width: auto;
    background-color: $base-bg-color;
    border: none;

    .dropdown-item {
        color: $knot-blue;
        font-weight: bolder;
        font-size: 1.2rem;
        font-family: "futura-pt-bold", serif;

        &:hover {
            background-color: $base-bg-color;
        }
    }
}

nav #rider-app-btn {
    margin-right: -15px;
    margin-left: 20px;
    border-radius: 50px 0 0 50px;
    background-color: $knot-blue;
    color: #ffffff;
    text-transform: uppercase;
}

#btn-ride {
    font-family: "futura-pt-bold", serif;
}

/**
 * Mobile only
 */
@media (max-width: 991.98px) {
    .navigation {
        padding: 5px 20px;
    }

    .navbar-brand img {
        height: 40px;
        padding: 8px;
    }

    .navbar-collapse {
        position: absolute;
        top: 63px;
        left: -50%;
        margin-left: -20px;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        transition: all 0.3s ease;
        display: block;
        border-radius: 0 25px 25px 0;
    }
    .navbar-collapse.collapsing {
        height: auto !important;
        margin-right: 50%;
        transition: all 0.3s ease;
        display: block;
    }
    .navbar-collapse.show {
        left: 0;
    }

    #navigation {
        background-color: $base-bg-color;
        z-index: 10000;
    }

    #btn-ride {
        position: absolute;
        right: 0;
        top: -63px;
        margin-right: -15px;
        border-radius: 50px 0 0 50px;
        padding: 9px 5px 9px 20px;
        z-index: 999999;
    }

    nav #rider-app-btn {
        margin-right: -38px;
        padding: 8.5px 15px 8.5px 25px;
    }
}

/**
 * Desktop only (lg+)
 */
@media (min-width: 992px) {
    .navigation.sticky-top .navbar {
        background-color: #cacaca;
    }

    .navbar .nav-item .nav-link {
        font-size: 1.4rem;
    }

    .navbar .dropdown-menu.horizontal.show {
        display: inline-flex;

        .dropdown-item:hover {
            border-radius: 30px;
        }

        .dropdown-item {
            min-width: 6rem;
            width: auto;
        }
    }

    #btn-ride {
        margin-left: -15px;
        border-radius: 0 50px 50px 0;
        padding: 10px 35px;
    }
}
